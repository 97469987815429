$(document).ready(function(e) {
 	// var slidetime = 30ms 
	var temp;

	$(".map_roi").mouseover(function() {
	
		temp = $(this).parent().parent().next().find("div");
	    temp.slideDown(30).html($(this).next().html());
		
	}).mouseout(function() {
	    temp.slideUp(30);
	});	

	$('area').click(function(event){ event.preventDefault(); return false; });	
});